import React from 'react';
import { useParams } from 'react-router-dom';
import organizationsData from '../data/organizations.json';
import styles from './HomePage.module.css';
import logo from '../assets/PeoplesLobbyLogo.png'; // Import the logo image

const OrganizationPage = () => {
  const { id } = useParams();
  const organization = organizationsData.find(org => org.id === id);

  // If the organization is not found
  if (!organization) {
    return <h2>Organization not found</h2>;
  }

  // Outline color based on affiliation
  const getCardOutline = (affiliation) => {
    switch (affiliation.toLowerCase()) {
      case 'liberal':
        return { borderColor: 'blue' };
      case 'conservative':
        return { borderColor: 'red' };
      case 'neutral':
        return { borderColor: '#FF9554' };
      default:
        return { borderColor: 'grey' };
    }
  };

  return (
    <div className={styles.fullContainer}>
      {/* Logo */}
      <img src={logo} alt="The People's Lobby Logo" className={styles.logo} />
      
      {/* Organization Details Card */}
      <div
        className={styles.orgCard}
        style={{ ...getCardOutline(organization.affiliation) }}
      >
        {/* Organization Name */}
        <h1 className={styles.orgTitle}>{organization.name}</h1>
        
        {/* Organization Description */}
        <p className={styles.orgDescription}>{organization.description}</p>
        
        {/* Organization Causes */}
        <p className={styles.orgDetail}><strong>Causes:</strong> {organization.causes}</p>
        
        {/* Organization Category */}
        <p className={styles.orgDetail}><strong>Category:</strong> {organization.category}</p>
        
        {/* Organization Affiliation */}
        <p className={styles.orgDetail}><strong>Affiliation:</strong> {organization.affiliation}</p>

        {/* Organization Website Button */}
        {organization.link && (
          <a
            href={organization.link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.websiteButton}
          >
            Visit Website
          </a>
        )}
      </div>
    </div>
  );
};

export default OrganizationPage;
