//import React from 'react';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OrganizationPage from './pages/OrganizationPage';
import './App.css';  // Import the global CSS file
import ReactGA from 'react-ga4';
ReactGA.initialize('G-D9BS1N18VK');
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/organization/:id" element={<OrganizationPage />} />
        </Routes>
      </div>
    </Router>
  );

}



export default App;

