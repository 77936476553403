import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import organizationsData from '../data/organizations.json';
import styles from './HomePage.module.css';
import logo from '../assets/PeoplesLobbyLogo.png'; // Import the logo image

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAffiliation, setSelectedAffiliation] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const navigate = useNavigate();

  // Mapping function for general topics
  const getGeneralCategory = (specificCategory) => {
    if (/health|medic|hospital/i.test(specificCategory)) {
      return "Healthcare";
    }
    if (/educat|school|teacher/i.test(specificCategory)) {
      return "Education";
    }
    if (/econom|business|market|tax|fiscal|finance|small business/i.test(specificCategory)) {
      return "Economy/Taxes";
    }
    if (/immig|border/i.test(specificCategory)) {
      return "Immigration";
    }
    if (/foreign|israel|conflict|national security/i.test(specificCategory)) {
      return "Foreign Conflict/Policy";
    }
    if (/labor|work|union/i.test(specificCategory)) {
      return "Labor/Workers' Rights";
    }
    if (/environment|climate|animal/i.test(specificCategory)) {
      return "Environment";
    }
    if (/social|civil|right|freedom|justice|lgbtq/i.test(specificCategory)) {
      return "Civil Rights/Social Issues";
    }
    if (/energy|oil|natural gas/i.test(specificCategory)) {
      return "Energy";
    }
    if (/gun|2nd amendment/i.test(specificCategory)) {
      return "Gun Control/2nd Amendment";
    }
    if (/religion|faith|family/i.test(specificCategory)) {
      return "Religion/Family Values";
    }
    if (/campaign|democrat|republican|liberal|conservative/i.test(specificCategory)) {
      return "Politics/Campaigns";
    }
    // Add more mappings as needed...
  
    return "Other"; // Default general category
  };

  // Get unique general categories (maximum of 15)
  const categories = Array.from(
    new Set(organizationsData.map(org => getGeneralCategory(org.category)))
  ).slice(0, 15);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Update the selected affiliation filter
  const handleAffiliationFilter = (affiliation) => {
    setSelectedAffiliation(affiliation === selectedAffiliation ? '' : affiliation);
  };

  // Update the selected category filter
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const getCardOutline = (affiliation) => {
    switch (affiliation.toLowerCase()) {
      case 'liberal':
        return { borderColor: 'blue' };
      case 'conservative':
        return { borderColor: 'red' };
      case 'neutral':
        return { borderColor: '#FF9554' };
      default:
        return { borderColor: 'grey' };
    }
  };

  // Filter organizations based on search, affiliation, and category
  const filteredOrgs = organizationsData.filter(org => {
    const matchesSearch = org.name.toLowerCase().includes(searchTerm) || 
                          org.causes.toLowerCase().includes(searchTerm);
    const matchesAffiliation = selectedAffiliation ? org.affiliation.toLowerCase() === selectedAffiliation.toLowerCase() : true;
    const matchesCategory = selectedCategory ? getGeneralCategory(org.category) === selectedCategory : true;

    return matchesSearch && matchesAffiliation && matchesCategory;
  });

  return (
    <div className={styles.container}>
      {/* Logo */}
      <img src={logo} alt="The People's Lobby Logo" className={styles.logo} />
      
      <h1 className={styles.title}>The People’s Lobby</h1>
      
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by organization or cause..."
        value={searchTerm}
        onChange={handleSearch}
        className={styles.searchBar}
      />
      
      {/* Affiliation Filters */}
      <div className={styles.affiliationFilters}>
        <button
          onClick={() => handleAffiliationFilter('Liberal')}
          className={`${styles.filterButton} ${selectedAffiliation === 'Liberal' ? styles.active : ''}`}
        >
          Liberal
        </button>
        <button
          onClick={() => handleAffiliationFilter('Conservative')}
          className={`${styles.filterButton} ${selectedAffiliation === 'Conservative' ? styles.active : ''}`}
        >
          Conservative
        </button>
        <button
          onClick={() => handleAffiliationFilter('Neutral')}
          className={`${styles.filterButton} ${selectedAffiliation === 'Neutral' ? styles.active : ''}`}
        >
          Neutral
        </button>
      </div>

      {/* Sector Category Filter */}
      <div className={styles.categoryFilter}>
        <label htmlFor="category-select">Sector:</label>
        <select id="category-select" onChange={handleCategoryChange} value={selectedCategory}>
          <option value="">All Categories</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
      </div>

      {/* Organization Cards */}
      <div className={styles.cards}>
        {filteredOrgs.map(org => (
          <div
            key={org.id}
            className={styles.card}
            style={getCardOutline(org.affiliation)}
            onClick={() => navigate(`/organization/${org.id}`)}
          >
            <h2>{org.name}</h2>
            <p>{org.description}</p>
            <p><strong>Causes:</strong> {org.causes}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
